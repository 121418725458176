import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

//components
const Header = lazy(()=>import('./components/header.js'));
const Footer = lazy(()=>import('./components/footer.js'));
const Loading = lazy(()=>import('./components/loading.js'));
// pages
const Home = lazy(() => import('./pages/home.js'));
const Poker = lazy(()=>import('./pages/poker.js'));
const About = lazy(()=>import('./pages/about.js'));
const Contact = lazy(()=>import('./pages/contact.js'));
const Sportsbook = lazy(()=>import('./pages/sportsbook.js'));
const Online_Casino = lazy(()=>import('./pages/online_casino.js'));
const Racing = lazy(()=>import('./pages/racing.js'));
const Live_Casino = lazy(()=>import('./pages/live_casino.js'));
const Bonus_Promos = lazy(()=>import('./pages/bonus_promos.js'));
const Bankin = lazy(()=>import('./pages/bankin.js'));
const Register = lazy(()=>import('./pages/register.js'));
//legal
const Anti_Money_Laundering = lazy(()=>import('./legal/anti_money_laundering.js'));
const Account_Payouts_Bonuses = lazy(()=>import('./legal/account_payouts_bonuses.js'));
const Dispute_Resolution = lazy(()=>import('./legal/dispute_resolution.js'));
const Fairness_Rng = lazy(()=>import('./legal/fairness_rng.js'));
const Kyc_Policies = lazy(()=>import('./legal/kyc_policies.js'));
const Privacy_Personal_Data = lazy(()=>import('./legal/privacy_personal_data.js'));
const Responsible_Gaming = lazy(()=>import('./legal/responsible_gaming.js'));
const Self_Exclusion = lazy(()=>import('./legal/self_exclusion.js'));
const Terms_Service = lazy(()=>import('./legal/terms_service.js'));
//legas esp

const Anti_Money_Laundering_esp = lazy(()=>import('./legal_esp/anti_money_laundering_esp.js'));
const Account_Payouts_Bonuses_esp = lazy(()=>import('./legal_esp/account_payouts_bonuses_esp.js'));
const Dispute_Resolution_esp = lazy(()=>import('./legal_esp/dispute_resolution_esp.js'));
const Fairness_Rng_esp = lazy(()=>import('./legal_esp/fairness_rng_esp.js'));
const Kyc_Policies_esp = lazy(()=>import('./legal_esp/kyc_policies_esp.js'));
const Privacy_Personal_Data_esp = lazy(()=>import('./legal_esp/privacy_personal_data_esp.js'));
const Responsible_Gaming_esp = lazy(()=>import('./legal_esp/responsible_gaming_esp.js'));
const Self_Exclusion_esp = lazy(()=>import('./legal_esp/self_exclusion_esp.js'));
const Terms_Service_esp = lazy(()=>import('./legal_esp/terms_service_esp.js'));
function App() {
  return (
    <div className="App font_small">
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
            <Header/>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="poker" element={<Poker />} />
              <Route path="live_betting" element={<Sportsbook />} />
              <Route path="online_casino" element={<Online_Casino />} />
              <Route path="live_casino" element={<Live_Casino />} />
              <Route path="racebook" element={<Racing />} />
              <Route path="about" element={<About />} />
              <Route path="bonuses" element={<Bonus_Promos />} />
              <Route path="contact" element={<Contact />} />
              <Route path="banking" element={<Bankin />} />
              <Route path="register" element={<Register />} />

              <Route path="terms_service" element={<Terms_Service />} />
              <Route path="responsible_gaming" element={<Responsible_Gaming />} />
              <Route path="self_exclusion" element={<Self_Exclusion />} />
              <Route path="dispute_resolution" element={<Dispute_Resolution />} />
              <Route path="fairness_rng" element={<Fairness_Rng />} />
              <Route path="anti_money_laundering" element={<Anti_Money_Laundering />} />
              <Route path="kyc_policies" element={<Kyc_Policies />} />
              <Route path="privacy_personal_data" element={<Privacy_Personal_Data />} />
              <Route path="account_payouts_bonuses" element={<Account_Payouts_Bonuses />} />

              <Route path="terms_service_esp" element={<Terms_Service_esp />} />
              <Route path="responsible_gaming_esp" element={<Responsible_Gaming_esp />} />
              <Route path="self_exclusion_esp" element={<Self_Exclusion_esp />} />
              <Route path="dispute_resolution_esp" element={<Dispute_Resolution_esp />} />
              <Route path="fairness_rng_esp" element={<Fairness_Rng_esp />} />
              <Route path="anti_money_laundering_esp" element={<Anti_Money_Laundering_esp />} />
              <Route path="kyc_policies_esp" element={<Kyc_Policies_esp />} />
              <Route path="privacy_personal_data_esp" element={<Privacy_Personal_Data_esp />} />
              <Route path="account_payouts_bonuses_esp" element={<Account_Payouts_Bonuses_esp />} />

            </Routes>
            <Footer/>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
